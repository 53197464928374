<template>
  <div class="productBox">
    <!-- 多样化服务 -->
    <div class="productNav">
      <img class="productBei" src="../assets/img/chanpin.png" alt="" />
      <h3 class="productTitle">多样化服务</h3>
      <p class="diversify animate__animated animate__fadeInUp">
        订单共享、智能调度、终端加持 标准流程、专业管理、突出时效
      </p>
      <!-- <h1 class="animate__animated animate__fadeInUp">An animated element</h1>    -->
    </div>
    <!-- 产品介绍 -->
    <div class="referral">
      <div class="referralBox">
        <h3 class="referralTit">产品介绍</h3>
        <div class="referrMain">
          <div class="referr1" v-for="(item, index) in referrList" :key="index">
            <img class="referr1Img" :src="item.img" alt="" />
            <h3 class="referr1Tit">{{ item.tit }}</h3>
            <p class="referr1Main">{{ item.main }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 标题 -->
    <div class="caption">
      <div class="captionBox">
        <h3 class="captionTit">平台优势</h3>
        <div class="captionMatter">
          <div
            class="caption1"
            v-for="(item, index) in captionList"
            :key="index"
          >
            <img class="caption1Img" :src="item.img" alt="" />
            <h3 class="caption1Tit">{{ item.tit }}</h3>
            <p class="caption1Main">{{ item.main }}</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 底部标题 -->
    <div class="bottomCap">
      <div class="captionBox">
        <div class="bomMain">
          <div class="bom" v-for="(item, index) in bomList" :key="index">
            <img class="bomImg" :src="item.img" alt="" />
            <h3 class="bomTit">{{ item.tit }}</h3>
            <p class="bom1Main">{{ item.main }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  data() {
    return {
      referrList: [
        {
          img: require("../assets/img/Frame4307.png"),
          tit: "区域运营公司",
          main: "负责区域市场运营，供需关系、公共关系、客户开发及维护和招商等工作。",
        },
        {
          img: require("../assets/img/Frame4428.png"),
          tit: "移动端工具",
          main: "负责区域市场运营，供需关系、公共关系、客户开发及维护和招商等工作。",
        },
        {
          img: require("../assets/img/Frame4430.png"),
          tit: "电脑客户端",
          main: "负责区域市场运营，供需关系、公共关系、客户开发及维护和招商等工作。",
        },
      ],
      captionList: [
        {
          img: require("../assets/img/Frame4304.png"),
          tit: "订单共享",
          main: "构建开放共赢的汽车救援共享新生态",
        },
        {
          img: require("../assets/img/Frame4314.png"),
          tit: "智能调度",
          main: "海量数据分析 创建智慧调度",
        },
        {
          img: require("../assets/img/Frame4324.png"),
          tit: "终端加持",
          main: "一机在手 万事无忧",
        },
        {
          img: require("../assets/img/Frame4420.png"),
          tit: "标准流程",
          main: "可视化服务流程 打造行业服务标杆",
        },
        {
          img: require("../assets/img/Frame4334.png"),
          tit: "专业管理",
          main: "行业内多年的实体经验，权威的行业知识",
        },
        {
          img: require("../assets/img/tuchu.png"),
          tit: "突出时效",
          main: " 解决时效问题 提升服务效率",
        },
      ],
      bomList: [
        {
          img: require("../assets/img/Frame4311.png"),
          tit: "多",
          main: "运力多、资源多、科技多",
        },
        {
          img: require("../assets/img/Frame4322.png"),
          tit: "快",
          main: "调度快、到达快、结算快",
        },
        {
          img: require("../assets/img/Frame4313.png"),
          tit: "好",
          main: "服务好、品质好、形象好",
        },
        {
          img: require("../assets/img/Frame4354.png"),
          tit: "省",
          main: "省成本、省人员、省时间",
        },
        {
          img: require("../assets/img/Frame4315.png"),
          tit: "全",
          main: "全流程、全地域、全区域",
        },
        {
          img: require("../assets/img/Frame4316.png"),
          tit: "稳",
          main: "能力稳、系统稳、资源稳",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.productNav {
  height: 640px;
  position: relative;
}
.productTitle {
  font-size: 48px;
  position: absolute;
  top: 99px;
  left: 72px;
}
.diversify {
  font-size: 30px;
  width: 455px;
  line-height: 38px;
  position: absolute;
  top: 251px;
  left: 72px;
  opacity: 0.9;
  // animation: dh 0.6s linear  forwards;
}
// @keyframes dh {
//     from {
//         transform: translateY(60px);
//     }
//     to {
//         transform: translateY(0px);
//     }
// }

.referral {
  height: 537px;
  background-color: #fff;
}
.referralBox {
  padding-top: 80px;
  padding-left: 72px;
}
.referralTit {
  font-size: 28px;
}

.referrMain {
  display: flex;
  margin-top: 30px;
}
.referr1 {
  margin-right: 30px;
  width: 412px;
  height: 308px;
  background-color: #f5f8ff;
  text-align: center;
  box-sizing: border-box;
}
.referr1Img {
  width: 80px;
  height: 80px;
  margin-top: 50px;
}
.referr1Tit {
  font-size: 24px;
  margin-top: 30px;
}
.referr1Main {
  width: 289px;
  margin-left: 51px;
  font-size: 16px;
  margin-top: 4px;
  text-align: left;
  color: rgba(0, 0, 0, 0.45);
}

// 标题
.caption {
  height: 600px;
  background-color: #f5f8ff;
}
.captionBox {
  padding-top: 80px;
  padding-left: 72px;
}
.captionTit {
  font-size: 28px;
}
.captionMain {
  width: 505px;
  font-size: 18px;
  margin-top: 30px;
  color: rgba(0, 0, 0, 0.45);
}
.captionMatter {
  margin-top: 30px;
  display: flex;
  overflow: hidden;
  width: 1296px;
  // overflow-x: scroll;
}
.caption1 {
  // width: 279px;
  height: 347px;
  padding: 15px;
  background-color: #fff;
  text-align: center;
  margin-right: 80px;
  // animation: picmove 15s linear infinite forwards;
}

@keyframes picmove {
  from {
    transform: translate(0);
  }
  to {
    transform: translate(-750px);
  }
}
.captionMatter:hover {
  animation-play-state: paused;
}

// .caption1Img{
//     width: 249px;
//     height: 185px;
// }
.caption1Tit {
  margin-top: 15px;
  font-size: 24px;
}
.caption1Main {
  text-align: left;
  width: 249px;
  // margin-left: 15px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 8px;
}

// 底部标题
.bottomCap {
  height: 530px;
  background-color: #fff;
}
.bom {
  display: flex;
  width: 412px;
  height: 140px;
  background-color: #f5f8ff;
  padding: 30px 0 0 30px;
  box-sizing: border-box;
  float: left;
  margin: 30px 30px 0 0;
}
.bomImg {
  width: 80px;
  height: 80px;
}
.bomTit {
  margin-left: 20px;
  margin-top: 10px;
}
.bom1Main {
  width: 185px;
  margin-left: -18px;
  margin-top: 52px;
}
</style>